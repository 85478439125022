// extracted by mini-css-extract-plugin
export var animatedBtn = "footer-module--animated-btn--V-cbh";
export var bannerHeader = "footer-module--banner-header--v1b7K";
export var btn = "footer-module--btn--dbqPx";
export var container = "footer-module--container--gYp13";
export var dark = "footer-module--dark--EDLOs";
export var detailsTextPortable = "footer-module--detailsTextPortable--zpWO-";
export var deviceMockupsWrapper = "footer-module--device-mockups-wrapper--Aul8E";
export var footer = "footer-module--footer--gNbb2";
export var footerLinksWrapper = "footer-module--footerLinksWrapper--aAenC";
export var headerFeatures = "footer-module--header-features--hFJte";
export var intro = "footer-module--intro--s9ihj";
export var lineIntro = "footer-module--line-intro--iv1V7";
export var link = "footer-module--link--ISMv5";
export var marqueText = "footer-module--marque-text--DV4Xn";
export var menuBackground = "footer-module--menu-background--WVOO-";
export var menuLink = "footer-module--menu-link--OpcQT";
export var overlay = "footer-module--overlay--02wnh";
export var pageBtn = "footer-module--page-btn--+1BbB";
export var question = "footer-module--question--fseUq";
export var testimonial = "footer-module--testimonial--TJfGU";
export var textColor = "footer-module--text-color--xR-7o";
export var textUppercase = "footer-module--text-uppercase--6jJBz";